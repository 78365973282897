<template>
  <section class="constructor-search-output">
    <div class="constructor-search-output__filters" v-if="products.length > 0">
      <!-- Filtros  -->
      <!-- <CatalogueDesktopFilters /> -->
    </div>
    <div class="constructor-search-output__products">
      <ProductCard
        v-for="(product, index) in products"
        :key="index"
        :product="product"
        :isConstructor="false"
      />
      <div class="constructor-search-output__more">
        <bbButton
          v-if="products.length > 0 && products.length % 40 === 0"
          class="button button--outline-dark"
          :loading="loading"
          label="Ver más"
          @click="verMas()"
        />
        <!--          @click="infiniteHandler()"-->
        <!--          v-if="lastPage === false && verMasNotNeeded === false"-->
        <!--        >-->
        
        <!-- if we want to show a message for "no more results" -->
        <!-- <span v-if="products.length % 40 !== 0">
          No hay más resultados a mostrar para esta búsqueda
        </span> -->
      </div>
    </div>
  </section>
</template>

<script>
import ProductCard from "./ProductCard.vue";
import CatalogueDesktopFilters from "../../catalogue/components/CatalogueDesktopFilters.vue";
import ProductItem from "../../../components/ProductItem.vue";
import { mapGetters } from "vuex";
import bbButton from "../../../components/buttons/bbButton.vue";

export default {
  name: "SearchConstructorResultados",
  components: {
    ProductItem,
    CatalogueDesktopFilters,
    ProductCard,
    bbButton,
  },
  computed: {
    ...mapGetters({
      products: "productsConstructor",
      constructorQuery: "constructorQuery",
    }),
  },
  data() {
    return {
      loading: false,
      lastPage: false,
      verMasNotNeeded: false,
    };
  },
  methods: {
    verMas() {
      this.loading = true;
      this.$emit("verMas");
    },
    isLoading(isl) {
      this.loading = isl;
    },
    showResults() {
      return this.products.length > 0;
    },
    resetResults() {
      this.$store.dispatch('resetProducts');
    },
  },
  beforeDestroy() {
    //this.resetResults()
  }
};
</script>
