<template>
  <div class="constructor-layout-simple bg-cercles" id="constructor" v-show="show">
    <header class="constructor-layout-simple__header">
      <div class="added-alert">
        <div class="bb-container bb-container--lista">
          <div class="added-alert__center">
            <portal-target name="alerts_constructor_page"></portal-target>
          </div>
        </div>
      </div>

      <!-- Sección app download //////////// -->
      <mq-layout :mq="['m480', 'm576', 'm768']">
        <section class="constructor-layout-simple__app" v-if="listActive">
          <banner-download-mobile-apps></banner-download-mobile-apps>
        </section>
      </mq-layout>
    </header>
    <!-- Sección Constructor //////////// -->
    <div class="bb-container bb-container--lista">
      <main
        class="constructor-layout-simple__content constructor-layout-simple__content--compact"
      >
        <Notification
          v-if="showNotification"
          tipo="error"
          @close-notification="closeNotification()"
        >
          {{ errorMessage }}
        </Notification>

        <mq-layout :mq="['m992', 'm1200']">
          <div class="constructor-layout__main-banner">
            <CtaExtensionBanner />
          </div>
        </mq-layout>

        <div
          class="constructor-layout-simple__search constructor-layout-simple__search--compact"
        >
          <SearchConstructorInitial @error="handleError" />
        </div>
        <div class="constructor-layout-simple__howto">
          <div class="search-hiw">
            <div class="search-hiw__col">
              <mq-layout mq="m992+">
                <picture class="modal-hiw__picture">
                  <img
                    src="../../assets/img/covers/cover-elige-compact-es.svg"
                    alt="¿Cómo funciona?"
                  />
                </picture>
              </mq-layout>
            </div>
            <div class="search-hiw__col">
              <header class="search-hiw__title">¿Cómo funciona?</header>
              <!-- <mq-layout mq="m992+">
                <div class="search-hiw__subtitle">
                  Guarda cosas en tu lista de HelloBB así:
                </div>
              </mq-layout> -->
              <main class="modal-hiw__items">
                <div class="modal-hiw__item">
                  <div class="modal-hiw__step">
                    <div class="modal-hiw__cercle">1</div>
                  </div>
                  <div class="modal-hiw__desc">
                    Busca en la web de cualquier tienda algo que te guste.
                  </div>
                </div>
                <div class="modal-hiw__item">
                  <div class="modal-hiw__step">
                    <div class="modal-hiw__cercle">2</div>
                  </div>
                  <div class="modal-hiw__desc">Copia su enlace.</div>
                </div>
                <div class="modal-hiw__item">
                  <div class="modal-hiw__step">
                    <div class="modal-hiw__cercle">3</div>
                  </div>
                  <div class="modal-hiw__desc">Pégalo en el formulario de arriba.</div>
                </div>
              </main>
            </div>
          </div>
        </div>
      </main>
    </div>
  </div>
</template>

<script>
import BannerDownloadMobileApps from "./components/BannerDownloadMobileApps";
import CtaExtensionBanner from "./components/CtaExtensionBanner.vue";
import CanGetOwnerList from "../../Mixins/CanGetOwnerList";
import { mapGetters } from "vuex";

import SearchConstructorInitial from "./components/SearchConstructorInitial";
import Notification from "../../components/ui/Notification.vue";

export default {
  name: "ConstructorInitialPage",
  mixins: [CanGetOwnerList],
  components: {
    Notification,
    BannerDownloadMobileApps,
    CtaExtensionBanner,
    SearchConstructorInitial,
  },
  metaInfo() {
    return {
      title: "Construye tu lista | HelloBB",
    };
  },
  data() {
    return {
      show: false,
      error: this.$route.params.error || false,
      errorMessage: this.$route.params.message || "",
      showNotification: this.$route.params.message || "",
    };
  },
  computed: {
    ...mapGetters({
      listConstructor: "listConstructor",
      limit: "limit",
      listActive: "listActive",
    }),
    hasProducts() {
      if (this.list) {
        if (this.list.products) {
          return this.list.products.length > 0;
        }
      }
      return false;
    },
  },
  async serverPrefetch() {
    //await this.$store.dispatch("getListConstructor");
    //console.log("serverPrefetch", this.$store.state.listConstructor)
  },
  async mounted() {
    console.log("mounted", this.$store.state.limit);
    console.log("mounted", this.$store.state.listConstructor);
    await this.$store.dispatch("setLimitSectionsToShow");
    // if (this.$utils.isEmpty(this.listConstructor)) {
    //   await this.$store.dispatch("getListConstructor");
    // }
    await this.$store.dispatch("getListConstructor");
    this.show = true;
    this.$store.commit("setCanScroll");
  },
  methods: {
    closeNotification() {
      this.errorMessage = "";
      this.showNotification = false;
    },
    handleError(message) {
      this.error = true;
      this.errorMessage = message;
      this.showNotification = message || "";
    },
  },
  created() {
    if (this.error) {
      this.showNotification = true;
    }
  },
};
</script>
