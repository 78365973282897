<template>
  <div>
    <ConstructorInternationalPage v-if="!featureCatalogAvailable" />
    <ConstructorInitialPage v-else-if="!listActive" />
    <ConstructorPage v-else />
  </div>
</template>

<script>
import { mapGetters } from "vuex";

import ConstructorPage from "./ConstructorPage.vue";
import ConstructorInternationalPage from "./ConstructorInternationalPage.vue";
import ConstructorInitialPage from "./ConstructorInitialPage.vue";

import CanGetOwnerList from "../../Mixins/CanGetOwnerList";
import Features from "../../Mixins/Features";

export default {
  name: "ConstructorSelector",
  mixins: [
    CanGetOwnerList,
    Features,
  ],
  computed: {
    ...mapGetters({
      listActive: "listActive",
    }),
  },
  components: {
    ConstructorPage,
    ConstructorInternationalPage,
    ConstructorInitialPage,
  },
};
</script>
