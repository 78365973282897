<template>
  <div>
    <modal-dialog
      :show="showModal"
      @close="close"
      target-class="modal__card--790"
    >
      <div class="cstart">
        <div class="cstart__title">¿Cómo funciona esto?</div>
        <div class="cstart__cols">
        <iframe width="100%" height="315" src="https://www.youtube.com/embed/rc3xPMywbSA" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
        </div>
        <div class="cstart__disclaimer">
          Si tienes preguntas, consulta nuestra sección de
          <router-link class="link link--underline link--bold" to="/preguntas-recuentes">ayuda</router-link>
          o escríbenos en el chat.
        </div>
      </div>
    </modal-dialog>
  </div>
</template>

<script>
import ModalDialog from "../../../components/ui/ModalDialog.vue";

export default {
  components: {
    ModalDialog,
  },
  data() {
    return {
      showModal: true
    };
  },
  methods: {
    close() {
      const cookieName = "modalShown";
      document.cookie = `${cookieName}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;

      this.showModal = false;
    },
  }
};
</script>
