<template>
    <span class="">
      <modal-dialog
        :show="showModalFundTypes"
        @close="showModalFundTypes = false"
        target-class="modal__card"
      >
        <div class="card-create-fund">
          <div class="card-create-fund__title">
            <small class="card-create-fund__step">
              <span>{{ $t("constructor.funds.stepNumerator", { step: '1' }) }}</span>
            </small>
            <h3 class="header-3 w-70">{{ $t("constructor.funds.chooseATypeOfFund") }}</h3>
          </div>

          <div class="card-create-fund__grid">
            <div
              v-for="fund in funds"
              :key="fund.id"
              @click="createFund(fund)"
              class="card-create-fund__item"
            >
              <div class="card-create-fund__ico">
                <div class="ico-box ico-box--flex">
                  <img :src="$fundIcons[fund.id]" alt="Tipo de fondo" />
                </div>
              </div>
              <h4 class="card-create-fund__name" v-text="fund.title" />
            </div>
          </div>
        </div>
      </modal-dialog>

      <modal-dialog
        :show="showModalCreateFund"
        :back="true"
        :showContributionsFooter="!loggedInUserHasPiggyBankEnabled"
        @back="backToStep1()"
        @close="showModalCreateFund = false"
        target-class="modal__card"
      >
        <div class="flux-fondos">
          <div class="flux-fondos__header">
            <small class="flux-fondos__step">
              <span>{{ $t("constructor.funds.stepNumerator", { step: '2' }) }}</span>
            </small>
            <h3 class="header-3 w-70">{{ $t("constructor.funds.createAFund") }}</h3>
          </div>
          <div class="flux-fondos__actions card-wizard__actions--mt-0">
            <div class="flux-fondos__content">
              <div class="flux-fondos__left">
                <div class="flux-fondos__name" v-text="selectedFund.title" />
                <div class="ico-box ico-box--flex">
                  <img :src="$fundIcons[selectedFund.id]" alt="Tipo de fondo" />
                </div>
              </div>
              <div class="flux-fondos__right">
                <div class="flux-fondos-step">
                  <div class="flux-fondos-step__form">
                    <div class="bb-form-item">
                      <label class="bb-form-label" for="title">{{ $t("mylist.fundModal.fundName") }}</label>
                      <input
                        id="title"
                        type="text"
                        class="bb-form"
                        v-model="title"
                        required
                        :placeholder="$t('constructor.funds.fundNamePlaceholder')"
                        @blur="titleBlur"
                      />
                      <div class="bb-form-error" v-if="titleHasError">
                        {{ $t("generic.field_is_required")}}
                      </div>
                    </div>
                    <div class="bb-form-item">
                      <label class="bb-form-label" for="comments">{{ $t("mylist.fundModal.comments") }}</label>
                      <textarea
                        id="comments"
                        class="bb-form-textarea"
                        v-model="comments"
                        rows="3"
                        required
                        :placeholder="$t('constructor.funds.commentsPlaceholder')"
                      />
                    </div>
                  </div>

                  <div class="flux-fondos-step__actions">
                    <loading-button v-if="loading" />
                    <button
                      v-else
                      class="button button--primary button--block"
                      @click="create"
                      :disabled="disabled"
                    >
                      {{ $t("constructor.funds.createButton") }}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </modal-dialog>

      <modal-dialog
        :show="showModalFundCreated"
        @close="showModalFundCreated = false"
        target-class="modal__card--mini"
      >
        <div class="card-success">
          <div class="card-success__ico mar-t-0">
            <img src="../../../assets/img/ico/ico-round-fondos-success.svg" alt="" />
          </div>
          <h3 class="card-success__header">{{ $t("constructor.funds.fundAdded") }}</h3>

          <div v-if="loggedInUserHasPiggyBankEnabled" class="card-success__content mar-b-0">
            {{ $t("constructor.funds.fundAddedText1") }}&nbsp;<a
              @click.prevent="$router.push({ name: 'owner-list' })"
              class="link link--bold link--underline"
              >{{ $t("constructor.funds.fundAddedText2") }}</a
            >.
          </div>

          <div v-if="!loggedInUserHasPiggyBankEnabled && !hidePiggyBank" class="card-success__content mar-b-0">
            {{ $t("constructor.funds.fundAddedNoPiggybankText1") }}&nbsp;<a
              @click.prevent="$router.push({ name: 'hucha-crear' })"
              class="link link--bold link--underline"
              >{{ $t("constructor.funds.fundAddedNoPiggybankText2") }}</a
            >.
          </div>
        </div>
      </modal-dialog>
    </span>
  </template>

  <script>
  import ModalDialog from "../../../components/ui/ModalDialog";
  import { mapGetters } from "vuex";
  import "floating-vue/dist/style.css";
  import { VTooltip } from "floating-vue";

  export default {
    name: "FundAddButton",
    components: {
      ModalDialog,
      LoadingButton: () =>
        import(
          /* webpackPrefetch: true */ /* webpackChunkName: "LoadingButton" */ "../../../components/LoadingButton"
        ),
    },
    directives: {
      tooltip: VTooltip,
    },
    data() {
      return {
        showModalFundTypes: false,
        showModalCreateFund: false,
        showModalFundCreated: false,
        selectedFund: {},
        title: "",
        titleDirty: false,
        comments: "",
        loading: false,
      };
    },
    props: {
      display: {
        default: "button",
      },
    },
    computed: {
      ...mapGetters({
        list: "ownerList",
        loggedInUserHasPiggyBankEnabled: "loggedInUserHasPiggyBankEnabled",
        hidePiggyBank: "hidePiggyBank",
      }),
      disabled() {
        if (this.title === "") return true;
        return false;
      },
      titleHasError() {
        return this.title === "" && this.titleDirty;
      },
    },
    methods: {
      getList() {
        return this.$store.dispatch("getOwnerList");
      },
      openConfiguration() {
        this.$router.push({ name: "configuration" });
      },
      backToStep1() {
        this.showModalCreateFund = false;
        this.$sleep(() => {
          this.showModalFundTypes = true;
        }, 200);
      },
      titleBlur() {
        this.titleDirty = true;
      },
      createFund(fund) {
        this.selectedFund = fund;
        this.showModalFundTypes = false;
        this.$sleep(() => (this.showModalCreateFund = true), 100);
      },
      async create() {
        this.loading = true;
        try {
          const fund = {
            title: this.title,
            comments: this.comments,
            fundTypeId: this.selectedFund.id,
            listId: this.list.id,
          };
          await this.$store.dispatch("addFund", fund);
          await this.getList();
          window.mixpanel.people.set_once({ "First Add": new Date().toISOString() });
          window.mixpanel.people.set({ "Last Add": new Date().toISOString() });
          window.mixpanel.people.increment("Total Add");
          window.mixpanel.track("Afegir objecte", {
            "Item Name": this.title,
            "Add Platform": this.$getPlatform(),
            "Add client": "Webapp",
            "Add type": "Fons",
            "Fund type": this.selectedFund.id,
            "Fund description": this.comments,
            Source: "Tu Cuenta",
          });
          this.showModalCreateFund = false;
          this.$sleep(() => {
            this.showModalFundCreated = true;
          }, 200);
        } catch (error) {
          console.log(error);
        }
        this.loading = false;
      },
      addFund() {
        this.showModalFundTypes = true
        
        // DESACIVEM LA VERSIÓ NATIVA DE LA APP

        // if (this.$utils.isApp()) window.location.href = window.location.pathname + "#addfund";
        // else this.showModalFundTypes = true
      },
      goToList() {
        if (!this.$utils.isApp()) this.$router.push({ name: "owner-list" });
        else location.href = process.env.APP_URL + "mi-lista?source=app";
      },
    },
    created() {
      this.funds = [
        {
          id: 1,
          title: this.$t("constructor.funds.yourFund"),
        },
        {
          id: 2,
          title: this.$t("constructor.funds.solidarityFunds"),
        },
        {
          id: 3,
          title: this.$t("constructor.funds.diapersFunds"),
        },
        {
          id: 4,
          title: this.$t("constructor.funds.parapharmacyFunds"),
        },
      ];
    },
  };
  </script>
