<template>
  <div class="search-wrapper">
    <div class="search-wrapper__search">
      <SearchConstructorInitialSearch @error="handleError" />
    </div>
  </div>
</template>

<script>
import SearchConstructorInitialSearch from "./SearchConstructorInitialSearch.vue";

export default {
  name: "SearchConstructorInitial",
  components: {
    SearchConstructorInitialSearch,
  },
  methods: {
    handleError(message) {
      this.$emit("error", message);
    },
  },
};
</script>
