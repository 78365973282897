<template>
  <span class="" @click.stop="">
    <button
      class="button button--outline-dark button--sm"
      @click="addFree"
    >
      Añade de cualquier tienda
    </button>

    <modal-dialog
      :show="showModalAddFree"
      @close="showModalAddFree = false"
      target-class="modal__card"
    >
      <div class="cs-add__content">
        <div class="cs-add__header">
          <h2 class="cs-add__title">Añade un objeto de otra tienda</h2>
          <p>Pega una dirección web de un objeto de cualquier tienda.</p>
        </div>
        <form class="cs-add__form">
          <div class="bb-form-inline">
            <div class="bb-form-inline__item">
              <input
                class="bb-form"
                :class="{ 'bb-form--danger': showParseError || urlHasError }"
                v-model="url"
                type="url"
                name="url"
                id="url"
                placeholder="Pega la URL del producto"
                @blur="urlDirty = true"
                style="font-size: 0.875rem;"
              />
              <div>
                <button
                  class="button button--primary button--block"
                  @click.prevent="addFreeProduct()"
                  :disabled="disabled"
                  style="font-size: 0.875rem;"
                >
                  Añadir  
                </button>
              </div>
            </div>
            <div class="bb-form-inline__errors">
              <div class="bb-form-error" v-if="showParseError" tabindex="0">
                Lo sentimos! Ha habido un error procesando la URL.
              </div>
              <div class="bb-form-error" v-if="urlHasError" tabindex="0">
                Por favor, pega aquí el enlace de un producto.
              </div>
            </div>
          </div>
        </form>
      </div>
    </modal-dialog>
  </span>
</template>

<script>
import { mapGetters } from "vuex";
import ModalDialog from "../../../components/ui/ModalDialog";

export default {
  name: "AddFreeProductButton",
  components: { ModalDialog },
  data() {
    return {
      url: "",
      urlDirty: false,
      showParseError: false,
      showModalAddFree: false,
      lastUrl: "",
    };
  },
  computed: {
    ...mapGetters({
      parserError: "parserError",
      parserErrorUrl: "parserErrorUrl",
    }),
    disabled() {
      return this.url === "" || !this.$utils.isValidUrl(this.url);
    },
    urlHasError() {
      return (this.url === "" || !this.$utils.isValidUrl(this.url)) && this.urlDirty;
    },
  },
  methods: {
    addFreeProduct() {
      this.showModalAddFree = false
      this.$router.push({ name: "add-product", query: { url: encodeURI(this.url) } });
    },
    trackCercaLliure() {
      window.mixpanel.people.set_once({ "Primera cerca lliure": this.url });
      window.mixpanel.track("Cerca lliure", {
        text: this.url,
      });
    },
    addFree() {
      if (this.$utils.isApp()) window.location.href = window.location.pathname + "#addfree";
      else this.showModalAddFree = true;
    }
  },
  mounted() {
    if (this.parserError) {
      this.showModalAddFree = true;
      this.showParseError = true;
      this.url = this.parserErrorUrl;
      this.$store.commit("setParserError", false);
      this.$store.commit("setParserErrorUrl", "");
    }
  },
};
</script>
