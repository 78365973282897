<template>
  <div :class="'cta-caja-sm' + ' ' + activeCta.class" @click="clicking" v-show="showCta">
    <product-added-dialog-ok
      :showProductAddedConfirmation="showProductAddedConfirmation"
    />
    <picture class="cta-caja-sm__cover">
      <!-- <span class="cta-caja-sm__label">
        <span>Descuento <strong>-25%</strong></span>
      </span> -->
      <img :src="activeCta.desktopSrc" alt="" />
    </picture>
    <div class="cta-caja-sm__main">
      <div class="cta-caja-sm__content">
        <h4 class="cta-caja-sm__title">{{ activeCta.shortDescription }}</h4>
        <div class="cta-caja-sm__description">
          {{ activeCta.longDescription }}
        </div>
      </div>
      <div class="cta-caja-sm__actions">
        <loading-button v-if="loading" :class="'button-loading--sm'" />
        <button
          v-else
          class="button button--accent button--sm button--icoStart"
          @click.stop="addProduct(parentPage)"
          :disabled="added"
        >
          {{ buttonText }}
        </button>
      </div>
    </div>
    <modal-dialog
      :show="showProductAddedDialog"
      @close="showProductAddedDialog = false"
      target-class="modal__card--mini"
    >
      <product-added-to-list />
    </modal-dialog>
  </div>
</template>

<script>

import Product from "./Mixins/Product";
import ModalDialog from "../../../components/ui/ModalDialog";
import axios from "axios";
import ProductAddedDialogOk from "../../../components/ProductAddedDialogOk";

export default {
  name: "CtaMainBannerSm",
  data() {
    return {
      activeCta: {},
      arrayCta: null,
      showCta: true,
      product: {},
      showProductAddedConfirmation: false,
    };
  },
  mixins: [Product],
  components: {
    LoadingButton: () =>
      import(
        /* webpackPrefetch: true */ /* webpackChunkName: "LoadingButton" */ "../../../components/LoadingButton"
      ),
    ModalDialog,
    ProductAddedToList: () =>
      import(
        /* webpackPrefetch: true */ /* webpackChunkName: "ProductAddedToList" */ "../../../components/ProductAddedToList"
      ),
    ProductAddedDialogOk,
  },
  props: {
    parentPage: {
      type: String,
      required: false,
      default: "Banner Mutante",
    },
  },
  computed: {
    buttonText() {
      return this.added ? "Añadido" : "Añadir a mi lista";
    },
  },
  created() {},
  methods: {
    clicking() {
      if (this.parentPage == "Constructor - Banner Horitzontal") {
        mixpanel.track("Click element constructor", {
          Name: this.product.name,
          Section: "Secció inicial",
          "Section number": 0,
          Type: "Banner - Horizontal",
          Content: "Ecommerce",
        });
      } else if (this.parentPage == "mi-lista") {
        window.mixpanel.track("Click banner llista", {
          Name: this.product.name,
        });
      }
      this.browseProduct();
    },
  },
  async mounted() {
    //var response = await axios.get(
    //  "https://promoted-product-service.azurewebsites.net/api/" + "promotedproduct",
    //  {
    //    headers: {
    //      'Content-Type': 'application/json',
    //      'x-functions-key': '7yDtYOJ9OYwkzrL2uxnezat3m6jrDd156-DHOaQ7FQRfAzFug5BWMA=='
    //    }
    //  }
    //)

    //await axios.get("/cart/" + cartId);
    if (this.arrayCta == null) {
      var response = await axios.get("/promotedproduct");
      this.arrayCta = response.data.filter(function (el) {
        return el.bannerId == 1;
      });
    }

    //filter response.data where bannerId = 1
    // var data = response.data.filter(function (el) {
    //   return el.bannerId == 1;
    // });

    var ctaIndex = sessionStorage.getItem("ctaIndex");
    if (ctaIndex == null) {
      //get a randon int between 0 and Ctas.length - 1
      ctaIndex = Math.floor(Math.random() * this.arrayCta.length);
      //ctaIndex = 0;
      sessionStorage.setItem("ctaIndex", "0");
    } else {
      ctaIndex = parseInt(ctaIndex) + 1;
    }
    if (ctaIndex == this.arrayCta.length) ctaIndex = 0;
    sessionStorage.setItem("ctaIndex", ctaIndex, toString());
    var i = 0;
    while (
      this.isProductAddedToTheList(this.arrayCta[parseInt(ctaIndex)].id) &&
      i < this.arrayCta.length
    ) {
      ctaIndex = parseInt(ctaIndex) + 1;
      if (ctaIndex == this.arrayCta.length) ctaIndex = 0;
      sessionStorage.setItem("ctaIndex", ctaIndex, toString());
      i++;
    }
    if (i == this.arrayCta.length) this.showCta = false;
    this.activeCta = this.arrayCta[parseInt(ctaIndex)];
    this.product = {
      id: this.activeCta.id,
      name: this.activeCta.name,
      "brand-slug": this.activeCta["brand-slug"],
      retailerName: "HelloBB",
      productRetailers: [{ name: "HelloBB" }],
    };
    //this.checkIfProductIsAlreadyAddedToList();
  },
};
</script>
