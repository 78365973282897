<template>
  <span class="">
    <button
      @click="addFund"
      v-show="display === 'button'"
      class="button button--outline-dark button--sm"
      v-tooltip="{
        html: true,
        content:
          'Recolecta dinero para lo que necesites (pañales, guarderías, causas benéficas...)',
        handleResize: false,
        triggers: ['hover', 'focus'],
        // placement: 'auto-end',
      }"
      loading="eager"
    >
      Añadir fondo
    </button>

    <span
      class="link link--smaller link--dark link--bold"
      @click="addFund"
      v-show="display === 'text'"
    >
      Añadir fondo
    </span>

    <modal-dialog
      :show="showModalFundTypes"
      @close="showModalFundTypes = false"
      target-class="modal__card"
    >
      <div class="card-create-fund">
        <div class="card-create-fund__title">
          <small class="card-create-fund__step">
            <span>Paso 1 / 2</span>
          </small>
          <h3 class="header-3 w-70">Elige qué tipo de fondo quieres crear</h3>
        </div>

        <div class="card-create-fund__grid">
          <div
            v-for="fund in funds"
            :key="fund.id"
            @click="createFund(fund)"
            class="card-create-fund__item"
          >
            <div class="card-create-fund__ico">
              <div class="ico-box ico-box--flex">
                <img :src="$fundIcons[fund.id]" alt="Tipo de fondo" />
              </div>
            </div>
            <h4 class="card-create-fund__name" v-text="fund.title" />
          </div>
        </div>
      </div>
    </modal-dialog>
    <modal-dialog
      :show="showModalCreateFund"
      :back="true"
      @back="backToStep1()"
      @close="showModalCreateFund = false"
      target-class="modal__card"
    >
      <div class="flux-fondos">
        <div class="flux-fondos__header">
          <small class="flux-fondos__step">
            <span>Paso 2 / 2</span>
          </small>
          <h3 class="header-3 w-70">Crear un fondo</h3>
        </div>
        <div class="flux-fondos__actions card-wizard__actions--mt-0">
          <div class="flux-fondos__content">
            <div class="flux-fondos__left">
              <div class="flux-fondos__name" v-text="selectedFund.title" />
              <div class="ico-box ico-box--flex">
                <img :src="$fundIcons[selectedFund.id]" alt="Tipo de fondo" />
              </div>
            </div>
            <div class="flux-fondos__right">
              <div class="flux-fondos-step">
                <div class="flux-fondos-step__form">
                  <div class="bb-form-item">
                    <label class="bb-form-label" for="title">Nombre del fondo</label>
                    <input
                      id="title"
                      type="text"
                      class="bb-form"
                      v-model="title"
                      required
                      placeholder="Elige un nombre para tu fondo"
                      @blur="titleBlur"
                    />
                    <div class="bb-form-error" v-if="titleHasError">
                      Lo sentimos. El campo es obligatorio.
                    </div>
                  </div>
                  <div class="bb-form-item">
                    <label class="bb-form-label" for="comments">Comentarios</label>
                    <textarea
                      id="comments"
                      class="bb-form-textarea"
                      v-model="comments"
                      rows="3"
                      required
                      placeholder="Escribe aquí cualquier cosa que la gente que contribuya deba saber"
                    />
                  </div>

                  <div class="header-5">Formas de recibir el dinero</div>
                  <div v-if="checkPayments" style="color: red">
                    Debe seleccionar una forma de recibir el dinero
                  </div>
                  <div v-if="checkPaymentsInfo" style="color: red">
                    Necesitas configurar la forma de recibir el dinero
                  </div>
                  <div class="mar-b-1">
                    <div
                      class="bb-form-checkbox"
                      v-for="(payment, index) in paymentTypes"
                      :key="index"
                    >
                      <input
                        :id="payment.id"
                        :value="payment.id"
                        v-model="checkedPayments"
                        type="checkbox"
                        name="checkbox"
                        data-name="Checkbox"
                      /><label :for="payment.id" class="bb-form-checkbox__label"
                        >{{ payment.name }} (necesitas
                        <a
                          @click.prevent="openConfiguration"
                          target="_blank"
                          class="link link--underline"
                          >configurar</a
                        >
                        tu {{ payment.name }})</label
                      >
                    </div>
                  </div>
                </div>
                <div class="flux-fondos-step__actions">
                  <loading-button v-if="loading" />
                  <button
                    v-else
                    class="button button--primary button--block"
                    @click="create"
                    :disabled="disabled"
                  >
                    Crear Fondo
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </modal-dialog>
    <modal-dialog
      :show="showModalFundCreated"
      @close="showModalFundCreated = false"
      target-class="modal__card--mini"
    >
      <div class="card-success">
        <div class="card-success__ico mar-t-0">
          <img src="../../../assets/img/ico/ico-round-fondos-success.svg" alt="" />
        </div>
        <h3 class="card-success__header">Fondo añadido a tu lista</h3>
        <div class="card-success__content mar-b-0">
          Sigue buscando lo mejor para tu bebé o&nbsp;<a
            @click.prevent="$router.push({ name: 'owner-list' })"
            class="link link--bold link--underline"
            >revisa tu lista</a
          >.
        </div>
      </div>
    </modal-dialog>
  </span>
</template>

<script>
import ModalDialog from "../../../components/ui/ModalDialog";
import { mapGetters } from "vuex";
import "floating-vue/dist/style.css";
import { VTooltip } from "floating-vue";

export default {
  name: "FundAddButton",
  components: {
    ModalDialog,
    LoadingButton: () =>
      import(
        /* webpackPrefetch: true */ /* webpackChunkName: "LoadingButton" */ "../../../components/LoadingButton"
      ),
  },
  directives: {
    tooltip: VTooltip,
  },
  data() {
    return {
      showModalFundTypes: false,
      showModalCreateFund: false,
      showModalFundCreated: false,
      selectedFund: {},
      title: "",
      titleDirty: false,
      comments: "",
      loading: false,
      checkPayments: false,
      checkPaymentsInfo: false,
      checkedPayments: [],
    };
  },
  props: {
    display: {
      default: "button",
    },
  },
  computed: {
    ...mapGetters({
      list: "ownerList",
    }),
    disabled() {
      if (this.title === "" || this.checkedPayments.length === 0) return true;
      return false;
    },
    titleHasError() {
      return this.title === "" && this.titleDirty;
    },
  },
  methods: {
    getList() {
      return this.$store.dispatch("getOwnerList");
    },
    openConfiguration() {
      this.$router.push({ name: "configuration" });
    },
    backToStep1() {
      this.showModalCreateFund = false;
      this.$sleep(() => {
        this.showModalFundTypes = true;
      }, 200);
    },
    titleBlur() {
      this.titleDirty = true;
    },
    createFund(fund) {
      this.selectedFund = fund;
      this.showModalFundTypes = false;
      this.$sleep(() => (this.showModalCreateFund = true), 100);
    },
    getArrayPaymentTypes() {
      let arrayPaymentTypes = [];
      this.checkedPayments.forEach((item) => {
        arrayPaymentTypes.push(this.paymentTypes[item - 1].name);
      });
      return arrayPaymentTypes;
    },
    setupFundPaymentTypes() {
      var payp = this.$store.state.user?.paypal;
      var phon = this.$store.state.user?.phone;

      var checkedPaymentTypes = [];
      // eslint-disable-next-line no-unused-vars
      let notPaymentMethodConfigured = false;
      this.checkedPayments.forEach((item) => {
        if (parseInt(item) === 2) {
          if (payp == "" || payp == "undefined") {
            notPaymentMethodConfigured = true;
          }
          checkedPaymentTypes.push({
            fundPaymentTypeId: 1,
            info: payp || "",
          });
        }
        if (parseInt(item) === 1) {
          if (phon == "" || phon == "undefined") {
            notPaymentMethodConfigured = true;
          }
          checkedPaymentTypes.push({
            fundPaymentTypeId: 2,
            info: phon || "",
          });
        }
      });
      return checkedPaymentTypes;
    },
    async create() {
      this.loading = true;
      let fundPaymentTypes = this.setupFundPaymentTypes();
      try {
        const fund = {
          title: this.title,
          comments: this.comments,
          fundTypeId: this.selectedFund.id,
          listId: this.list.id,
          fundPaymentTypes: fundPaymentTypes,
        };
        await this.$store.dispatch("addFund", fund);
        await this.getList();
        window.mixpanel.people.set_once({ "First Add": new Date().toISOString() });
        window.mixpanel.people.set({ "Last Add": new Date().toISOString() });
        window.mixpanel.people.increment("Total Add");
        window.mixpanel.track("Afegir objecte", {
          "Item Name": this.title,
          "Add Platform": this.$getPlatform(),
          "Add client": "Webapp",
          "Add type": "Fons",
          "Fund type": this.selectedFund.id,
          "Fund description": this.comments,
          "Fund method": this.getArrayPaymentTypes(),
          Source: "Tu Cuenta",
        });
        this.showModalCreateFund = false;
        this.$sleep(() => {
          this.showModalFundCreated = true;
        }, 200);
      } catch (error) {
        console.log(error);
      }
      this.loading = false;
    },
    addFund() {
      if (this.$utils.isApp()) window.location.href = window.location.pathname + "#addfund";
      else this.showModalFundTypes = true
    }
  },
  created() {
    this.paymentTypes = [
      {
        id: 1,
        name: "Bizum",
      },
      {
        id: 2,
        name: "Paypal",
      },
    ];

    this.funds = [
      {
        id: 1,
        title: "Fondo a tu medida",
      },
      {
        id: 2,
        title: "Fondo solidario",
      },
      {
        id: 3,
        title: "Fondo para pañales",
      },
      {
        id: 4,
        title: "Fondo para parafarmacia",
      },
    ];
  },
};
</script>
