<template>
  <div class="search-wrapper">
    <div class="search-wrapper__search">
      <SearchConstructorInternationalSearch @isLoading="isLoading" @error="handleError" />
    </div>
  </div>
</template>

<script>
import SearchConstructorInternationalSearch from "./SearchConstructorInternationalSearch.vue";

export default {
  name: "SearchConstructorInternational",
  components: {
    SearchConstructorInternationalSearch,
  },
  methods: {
    isLoading(isl) {
      if (this.$refs.refResults) {
        this.$refs.refResults.isLoading(isl);
      }
    },
    handleError(message) {
      this.$emit("error", message);
    },
  },
};
</script>
