<template>
  <div>
    <modal-dialog :show="showModal" @close="close" target-class="modal__card--mini">
      <div class="add-items">
        <div class="add-items__title">
          <h3 class="header-3">Te damos ideas para tu lista</h3>
        </div>
        <div class="add-items__picture">
          
            <img
              src="../../../assets//img/covers/cover-regalos-help.svg"
              alt="te damos ideas para tu lista" />
        </div>
        <div class="add-items__info">
          <p>
            Puedes añadir lo que tengas claro copiando enlaces.
            Para lo que no, te iremos dando sugerencias.
          </p>
        </div>

        <div class="content-modal__form">
          <div class="content-modal__actions">
            <bbButton
              label="Entendido"
              :variant="'button button--primary'"
              @click="close"
            >
            </bbButton>
          </div>
        </div>

      </div>
    </modal-dialog>
  </div>
</template>

<script>
import ModalDialog from "../../../components/ui/ModalDialog.vue";
import bbButton from "../../../components/buttons/bbButton.vue";

export default {
  name: "FirstConstructorModal",
  components: {
    ModalDialog,
    bbButton
  },
  data() {
    return {
      showModal: true
    };
  },
  methods: {
    close() {
      const cookieName = "modalShown";
      document.cookie = `${cookieName}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;

      this.showModal = false;
    },
  }
};
</script>
