<template>
    <div :class="activeCta.class" @click="clicking" v-show="showCta">
      <product-added-dialog-ok :showProductAddedConfirmation="showProductAddedConfirmation"  />
      <div class="cta-caja__cover">
        <img
          class="cta-caja__cover-desk"
          v-bind:src="activeCta.desktopSrc"
          alt=""
        />
        <img
          class="cta-caja__cover-mobile"
          :src="activeCta.mobileSrc"
          alt=""
        />
      </div>
      <div class="cta-caja__content">
        <h4 class="cta-caja__title">{{activeCta.shortDescription}}</h4>
        <div class="cta-caja__description">
          <p>{{activeCta.longDescription}}</p>
        </div>
        <div class="cta-caja__actions">
          <loading-button v-if="loading" :class="'button-loading--sm'" />
          <button
            v-else
            class="button button--accent button--sm button--ico"
            @click.stop="addProduct(parentPage)"
            :disabled="added"
          >
            {{ buttonText }}
          </button>
        </div>
      </div>
      <modal-dialog
          :show="showProductAddedDialog"
          @close="showProductAddedDialog = false"
          target-class="modal__card--mini"
      >
        <product-added-to-list/>
      </modal-dialog>
    </div>
  </template>
  
  <script>
  const Ctas = [
    {
      id: 35518,
      name: "Sophie la girafe + Chupete 100% hevea natural",
      brandSlug: "sophie-la-girafe",
      shortDescription: "Set Sophie la Girafe: mordedores 100% naturales",
      longDescription: "Dos mordedores diferentes de la clásica jirafita que tu bebé adorará.",
      "desktopSrc": "https://images.hellobb.net/sophie-chupete-desktop.jpg",
      "mobileSrc": "https://images.hellobb.net/sophie-chupete-responsive.jpg",
      "class":"cta-caja cta-caja--yellow"
    },
    {
      id: 41763,
      name: "Contenedor de pañales Sangenic Twist and click",
      brandSlug: "tommee-tippee",
      shortDescription: "Contenedor de pañales Sangenic Twist and click",
      longDescription: "Olvídate del olor a pañal. Este contenedor los embala.",
      "desktopSrc": "https://images.hellobb.net/twist-and-click-desktop.png",
      "mobileSrc": "https://images.hellobb.net/twist-and-click-responsive.png",
      "class":"cta-caja cta-caja--verde"
    },
    {
      id: 42151,
      name: "Fular&mochila Boba Bliss",
      brandSlug: "boba",
      shortDescription: "Fular&mochila Boba Bliss",
      longDescription: "Suave como un fular, práctico como una mochila. Un portabebés con lo mejor de los dos mundos.",
      "desktopSrc": "https://images.hellobb.net/boba-bliss-desktop.jpg",
      "mobileSrc": "https://images.hellobb.net/boba-bliss-responsive.png",
      "class":"cta-caja cta-caja--lila"
    },
    {
      id: 41983,
      name: "Almohada de embarazo y lactancia Made for Me",
      brandSlug: "tommee-tippee",
      shortDescription: "Almohada de embarazo y lactancia Made for Me",
      longDescription: "Suave,  grande, blanda y cómoda. Estaréis como en una nube.",
      "desktopSrc": "https://images.hellobb.net/almohada-made-for-me-desktop.jpg",
      "mobileSrc": "https://images.hellobb.net/almohada-made-for-me-responsive.png",
      "class":"cta-caja cta-caja--lila"
    },
    {
      id: 42478,
      name: "Esterilizador de biberones Philips",
      brandSlug: "philips",
      shortDescription: "Esterilizador de biberones Philips",
      longDescription: "Vuestro bibes y chupetes sin gérmenes con este esterilizador de gran capacidad.",
      "desktopSrc": "https://images.hellobb.net/bm-esterilizador-philips-desktop.jpg",
      "mobileSrc": "https://images.hellobb.net/bm-esterilizador-philips-responsive.png",
      "class":"cta-caja cta-caja--lila"
    },
    {
      id: 42484,
      name: "Centro de actividades Bright Starts",
      brandSlug: "bright-starts",
      shortDescription: "Centro de actividades Bright Starts",
      longDescription: "Diversión pura para tu bebé.",
      "desktopSrc": "https://images.hellobb.net/centro-de-actividades-bright-starts-desktop.jpg",
      "mobileSrc": "https://images.hellobb.net/centro-de-actividades-bright-starts-responsive.png",
      "class":"cta-caja cta-caja--lila"
    },
    {
      id: 41985,
      name: "Kit anticólicos de Tommee Tippee",
      brandSlug: "tommee-tippee",
      shortDescription: "Kit anticólicos de Tommee Tippee",
      longDescription: "Los bibes que necesitas para para los primeros meses de tu bebé.",
      "desktopSrc": "https://images.hellobb.net/kit-anticolico-desktop.png",
      "mobileSrc": "https://images.hellobb.net/kit-anticolico-responsive.png",
      "class":"cta-caja cta-caja--verde"
    },
    {
      id: 36501,
      name: "Kit de cuidado del bebé Philips Avent",
      brandSlug: "philips-avent",
      shortDescription: "Kit de cuidado del bebé Philips Avent",
      longDescription: "Todo lo que necesitas para los cuidados de tu peque.",
      "desktopSrc": "https://images.hellobb.net/kit-cuidado-desktop.jpg",
      "mobileSrc": "https://images.hellobb.net/kit-cuidado-responsive.png",
      "class":"cta-caja cta-caja--lila"
    },
    {
      id: 38921,
      name: "Extractor de leche eléctrico Philips Avent",
      brandSlug: "philips-avent",
      shortDescription: "Extractor de leche eléctrico Philips Avent",
      longDescription: "Un modelo clásico de una gran marca.",
      "desktopSrc": "https://images.hellobb.net/extractor-desktop.jpg",
      "mobileSrc": "https://images.hellobb.net/extractor-responsive.jpg",
      "class":"cta-caja cta-caja--yellow"
    },
    {
      id: 42266,
      name: "Cojín de actividades de Sophie la Girafe",
      brandSlug: "sofie-la-girafe",
      shortDescription: "Cojín de actividades de Sophie la Girafe",
      longDescription: "Una cara suave para dormir y otra llena de actividades para divertirse.",
      "desktopSrc": "https://images.hellobb.net/cosy-play-desktop.jpg",
      "mobileSrc": "https://images.hellobb.net/cosy-play-responsive.jpg",
      "class":"cta-caja cta-caja--yellow"
    },
    {
      id: 42458,
      name: "Gimnasio de actividades de Tiny Love",
      brandSlug: "tiny-love",
      shortDescription: "Gimnasio de actividades de Tiny Love",
      longDescription: "Estimula el desarrollo físico y cognitivo de tu bebé desde su nacimiento.",
      "desktopSrc": "https://images.hellobb.net/bm-gimnasio-gymini-desktop.jpg",
      "mobileSrc": "https://images.hellobb.net/bm-gimnasio-gymini-responsive.jpg",
      "class":"cta-caja cta-caja--verde"
    },
    {
      id: 41759,
      name: "Hamaca Babybjörn Bliss",
      brandSlug: "baby-bjorn",
      shortDescription: "Hamaca Babybjörn Bliss",
      longDescription: "Descubre el diseño nórdico de la hamaca ergonómica favorita de nuestra comunidad.",
      "desktopSrc": "https://images.hellobb.net/bm-bliss-desktop.jpg",
      "mobileSrc": "https://images.hellobb.net/bm-bliss-responsive.png",
      "class":"cta-caja cta-caja--lila"
    },
    {
      id: 42508,
      name: "Set de juguetes de 0 a 4 meses",
      brandSlug: "oh-mybaby",
      shortDescription: "Set de juguetes de 0 a 4 meses",
      longDescription: "Cajas cargadas de diversión adaptada a cada fase de crecimiento de tu bebé.",
      "desktopSrc": "https://images.hellobb.net/bm-oh-my-baby-desktop.jpg",
      "mobileSrc": "https://images.hellobb.net/bm-oh-my-baby-responsive.png",
      "class":"cta-caja cta-caja--lila"
    }
  ];

  import Product from "./Mixins/Product";
  import ModalDialog from "../../../components/ui/ModalDialog";
  import axios from "axios";
  import ProductAddedDialogOk from "../../../components/ProductAddedDialogOk";
  
  export default {
    name: "CtaMainBanner",
    data(){
      return {
        activeCta: {},
        arrayCta: null,
        showCta: true,
        product: {},
        showProductAddedConfirmation: false,
      };
    },
    mixins: [Product],
    components: {
      LoadingButton: () => import( /* webpackPrefetch: true */ /* webpackChunkName: "LoadingButton" */  '../../../components/LoadingButton'),
      ModalDialog,
      ProductAddedToList: () => import( /* webpackPrefetch: true */ /* webpackChunkName: "ProductAddedToList" */  '../../../components/ProductAddedToList'),
      ProductAddedDialogOk
    },
    props : {
      parentPage: {
        type: String,
        required: false,
        default: "Banner Mutante"
      },
    },
    computed: {
      buttonText() {
        return this.added ? "Añadido" : "Añadir a mi lista";
      },
    },
    created() {
      
    },
    methods: {
      clicking()
      {
        if(this.parentPage == "Constructor - Banner Horitzontal")
        {
          mixpanel.track("Click element constructor", {
            "Name": this.product.name,
            "Section": "Secció inicial",
            "Section number": 0,
            "Type": "Banner - Horizontal",
            "Content": "Ecommerce"
          });
        }
        else if(this.parentPage == "mi-lista") {
          window.mixpanel.track("Click banner llista", {
            "Name": this.product.name,
          });
        }
        this.browseProduct();
      }
    },
    async mounted() {
      //var response = await axios.get(
      //  "https://promoted-product-service.azurewebsites.net/api/" + "promotedproduct",
      //  {
      //    headers: {
      //      'Content-Type': 'application/json',
      //      'x-functions-key': '7yDtYOJ9OYwkzrL2uxnezat3m6jrDd156-DHOaQ7FQRfAzFug5BWMA=='
      //    }
      //  }
      //)

      //await axios.get("/cart/" + cartId);
      if(this.arrayCta == null)
      {
        var response = await axios.get("/promotedproduct");
        this.arrayCta = response.data.filter(function (el) {
          return el.bannerId == 1;
        });;
      }
      
      //filter response.data where bannerId = 1
      // var data = response.data.filter(function (el) {
      //   return el.bannerId == 1;
      // });
      
      var ctaIndex = sessionStorage.getItem("ctaIndex");
      if(ctaIndex == null) {
        //get a randon int between 0 and Ctas.length - 1
        ctaIndex = Math.floor(Math.random() * this.arrayCta.length);
        //ctaIndex = 0;
        sessionStorage.setItem("ctaIndex", "0");
      }
      else {
        ctaIndex = parseInt(ctaIndex) + 1;
      }
      if (ctaIndex == this.arrayCta.length) ctaIndex = 0;
      sessionStorage.setItem("ctaIndex", ctaIndex,toString());
      var i = 0
      while (this.isProductAddedToTheList(this.arrayCta[parseInt(ctaIndex)].id) && i < this.arrayCta.length) {
        ctaIndex = parseInt(ctaIndex) + 1;
        if (ctaIndex == this.arrayCta.length) ctaIndex = 0;
        sessionStorage.setItem("ctaIndex", ctaIndex,toString());
        i++;
      }
      if (i == this.arrayCta.length) this.showCta = false
      this.activeCta = this.arrayCta[parseInt(ctaIndex)]
      this.product = {
        id: this.activeCta.id,
        name: this.activeCta.name,
        "brand-slug": this.activeCta["brand-slug"],
        retailerName: "HelloBB",
        productRetailers: [{"name": "HelloBB"}]
      };
      //this.checkIfProductIsAlreadyAddedToList();
    }
  };
  </script>