<template>
    <div class="cta-caja cta-caja--yellow" @click="clicking">
      <product-added-dialog-ok :showProductAddedConfirmation="showProductAddedConfirmation"  />
      <div class="cta-caja__cover">
        <img
          class="cta-caja__cover-desk"
          src="../../../assets/img/covers/cover-cta-sophie-chupete.jpg"
          alt=""
        />
        <img
          class="cta-caja__cover-mobile"
          src="../../../assets/img/covers/cover-cta-sophie-chupete-m.jpg"
          alt=""
        />
      </div>
      <div class="cta-caja__content">
        <h4 class="cta-caja__title">Set Sophie la Girafe: mordedores 100% naturales</h4>
        <div class="cta-caja__description">
          <p>Dos mordedores diferentes de la clásica jirafita que tu bebé adorará.</p>
        </div>
        <div class="cta-caja__actions">
          <loading-button v-if="loading" :class="'button-loading--sm'" />
          <button
            v-else
            class="button button--accent button--sm button--ico"
            @click.stop="addProduct('Constructor - Banner Horitzontal')"
            :disabled="added"
          >
            {{ buttonText }}
          </button>
        </div>
      </div>
      <modal-dialog
          :show="showProductAddedDialog"
          @close="showProductAddedDialog = false"
          target-class="modal__card--mini"
      >
        <product-added-to-list/>
      </modal-dialog>
    </div>
  </template>
  
  <script>
  import Product from "./Mixins/Product";
  import ModalDialog from "../../../components/ui/ModalDialog";
  import ProductAddedToList from "../../../components/ProductAddedToList";
  
  export default {
    name: "CtaSophieChupete",
    mixins: [Product],
    components: {
      LoadingButton: () => import( /* webpackPrefetch: true */ /* webpackChunkName: "LoadingButton" */  '../../../components/LoadingButton'),
      ModalDialog,
      ProductAddedToList: () => import( /* webpackPrefetch: true */ /* webpackChunkName: "ProductAddedToList" */  '../../../components/ProductAddedToList'),
      ProductAddedDialogOk: () => import( /* webpackPrefetch: true */ /* webpackChunkName: "ProductAddedDialogOk" */  '../../../components/ProductAddedDialogOk'),
    },
    data() {
      return {
        showProductAddedConfirmation: false,
      };
    },
    computed: {
      buttonText() {
        return this.added ? "Añadido" : "Añadir a mi lista";
      },
    },
    created() {
      this.product = {
        id: 35518,
        name: "Sophie la girafe + Chupete 100% hevea natural",
        "brand-slug": "sophie-la-girafe",
        retailerName: "HelloBB",
        productRetailers: [{"name": "HelloBB"}],
        shopifyProductId: "7760991977711",
        shopifyProductVariantId: "43102909825263"
      };
    },
    methods: {
      clicking()
      {
        mixpanel.track("Click element constructor", {
          "Name": "Sophie la girafe + Chupete 100% hevea natural",
          "Section": "Secció inicial",
          "Section number": 0,
          "Type": "Banner - Horizontal",
          "Content": "Ecommerce"
        });
        this.browseProduct();
      }
    }
  };
  </script>