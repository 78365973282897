<template>
  <div>
    <div id="constructor_sections" class="hiddenanchor"></div>
    <div
      class="explore-section"
      v-for="(section, index) in filteredSections"
      :key="'section' + index"
    >
      <LazyHydrate when-visible>
        <explore-section :section="section"></explore-section>
      </LazyHydrate>
    </div>
    <div
      class="explore-section"
      v-for="(initialSection, index) in initialSections"
      :key="'initial_section_' + index"
    >
      <LazyHydrate when-visible>
        <explore-section :section="initialSection"></explore-section>
      </LazyHydrate>
    </div>
    <div
      class="explore-section"
      v-for="(temporalFilteredSection, index) in temporalFilteredSections"
      :id="'new_section_' + index"
      :key="'new_section_' + index"
    >
      <LazyHydrate when-visible>
        <explore-section :section="temporalFilteredSection"></explore-section>
      </LazyHydrate>
    </div>
    <LazyHydrate when-visible>
      <explore-footer
        @verMas="verMas"
        :endOfconstructor="endOfConstructor"
      ></explore-footer>
    </LazyHydrate>
  </div>
</template>

<script>
import CtaSophieChupete from "./CtaSophieChupete.vue";
import CtaMainBanner from "./CtaMainBanner.vue";
import { mapGetters } from "vuex";
import LazyHydrate from "vue-lazy-hydration";

export default {
  name: "Explore",
  components: {
    ExploreFooter: () =>
      import(
        /* webpackPrefetch: true */ /* webpackChunkName: "ExploreFooter" */ "./ExploreFooter"
      ),
    ExploreSection: () =>
      import(
        /* webpackPrefetch: true */ /* webpackChunkName: "ExploreSection" */ "./ExploreSection"
      ),
    CtaSophieChupete,
    CtaMainBanner,
    LazyHydrate,
  },
  data() {
    return {
      newSections: [],
      currentLimit: 0,
      savedScrollPosition: 0,
    };
  },
  computed: {
    ...mapGetters({
      listConstructor: "listConstructor",
      limit: "limit",
      temporalLimit: "temporalLimit",
    }),
    endOfConstructor() {
      return (
        this.currentLimit + this.limit ===
        this.sections?.length + this.initialSections?.length
      );
    },
    numberOfFilteredSections() {
      return (
        this.limit + this.currentLimit - this.temporalLimit - this.initialSections.length
      );
    },
    initialSections() {
      let initialSections = this.listConstructor["initial_sections"];
      if (initialSections) {
        return initialSections;
      }
      return [];
    },
    sections() {
      return this.listConstructor?.sections;
    },
    filteredSections() {
      if (
        this.numberOfFilteredSections === 0 ||
        this.numberOfFilteredSections === null ||
        typeof this.numberOfFilteredSections === "undefined"
      )
        return [];
      if (this.sections) {
        if (this.sections.length === 0) return [];
        let filteredSections = [...this.sections?.slice(-this.numberOfFilteredSections)];
        if (filteredSections) {
          if (filteredSections.length > 0)
            return filteredSections.map((filteredSection, index) => {
              filteredSection.new = false;
              if (index === 0) {
                filteredSection.new = true;
              }
              return filteredSection;
            });
        }
      }
      return [];
    },
    temporalFilteredSections() {
      var res = this.newSections;
      if (this.currentLimit === 0) {
        this.newSections = [];
        var i = 1;
        while (i <= this.temporalLimit) {
          res.push(
            this.sections[
              this.sections.length -
                (this.limit - this.temporalLimit + i - this.initialSections.length)
            ]
          );
          i++;
        }
      }
      return res;
    },
  },
  methods: {
    verMas() {
      this.currentLimit = this.currentLimit + 1;
      this.savedScrollPosition = window.pageYOffset || document.documentElement.scrollTop;
      this.newSections.push(
        this.sections[
          this.sections.length -
            (this.limit + this.currentLimit - this.initialSections.length)
        ]
      );
      this.$store.dispatch("setLimitSectionsToShow2");
      this.$store.commit("setTemporalLimit", this.temporalLimit + 1);

      this.$nextTick(() => {
        var newSection = document.getElementById(
          "new_section_" + (this.currentLimit - 1)
        );

        //get height of the header by class name = header
        var headerHeight = document.getElementsByClassName("header")[0].offsetHeight;
        var bodyRect = document.body.getBoundingClientRect();
        var elemRect = newSection.getBoundingClientRect();
        var offset = elemRect.top - bodyRect.top;
        window.scrollTo({
          top: offset - headerHeight,
          behavior: "smooth",
        });
      });

      //track it in mixpanel
      window.mixpanel.track("Desplegar secció constructor", {
        Section: this.currentLimit + this.limit,
        "Section name":
          this.sections[
            this.sections.length -
              (this.limit + this.currentLimit - this.initialSections.length)
          ].title,
      });
    },
  },
};
</script>

<style>
.hiddenanchor {
  display: block;
  content: " ";
  margin-top: -100px;
  height: 100px;
  visibility: hidden;
  pointer-events: none;
}
</style>
