<template>
  <div class="search-wrapper">
    <div class="search-wrapper__search">
      <SearchConstructorSearch  @isLoading="isLoading" ref="refSearch" />
    </div>
    <!-- <catalogue-filters /> -->
    <applied-filters />
    <div class="search-wrapper__output"  v-if="showResults">
     <SearchConstructorResultados @verMas="verMas" ref="refResults" />
    </div>
  </div>
</template>

<script>
import SearchConstructorSearch from "./SearchConstructorSearch.vue";
import SearchConstructorResultados from "./SearchConstructorResultados.vue";
import AppliedFilters from "../../../components/AppliedFilters.vue";
import CatalogueFilters from "../../catalogue/components/CatalogueFilters.vue";

export default {
  name: "SearchConstructor",
  components: {
    CatalogueFilters, AppliedFilters,
    SearchConstructorSearch,
    SearchConstructorResultados,
  },
  data() {
    return {
      search: false,
    };
  },
  computed: {
    showResults() {
      return this.$store.getters.productsConstructor.length > 0;
    },
  },
  methods: {
    verMas() {
      if (this.$refs.refSearch) {
        this.$refs.refSearch.verMas()
      }
    },
    isLoading(isl) {
      if (this.$refs.refResults) {
        this.$refs.refResults.isLoading(isl);
      }
    }
  },
};
</script>
