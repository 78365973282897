<template>
  <div>
    <section class="card-ext">
      <div class="card-ext__header">{{ $t("constructor.extensionModal.title") }}</div>
      <picture class="card-ext__cover">
        <img src="../../../assets/img/covers/cover-ext-share.png" alt="" />
      </picture>
      <aside class="card-ext__content" v-if="isChrome">
        <div class="modal-extension">
          <div class="modal-extension__cta">
            <a
              :href="$t('extension.url')"
              :title="$t('extension.title')"
              target="_blank"
              class="button button--primary"
            >
              {{ $t("extension.title") }}
            </a>
          </div>

          <main class="modal-extension__items">
            <div class="modal-extension__item">
              <div class="modal-extension__step">
                <div class="modal-extension__cercle">1</div>
              </div>
              <div
                class="modal-extension__desc"
                v-html="$t('constructor.extensionModal.htmlInstructionsChrome1')"
              ></div>
            </div>

            <div class="modal-extension__item">
              <div class="modal-extension__step">
                <div class="modal-extension__cercle">2</div>
              </div>
              <div
                class="modal-extension__desc"
                v-html="$t('constructor.extensionModal.htmlInstructionsChrome2')"
              ></div>
            </div>

            <div class="modal-extension__item">
              <div class="modal-extension__step">
                <div class="modal-extension__cercle">3</div>
              </div>
              <div
                class="modal-extension__desc"
                v-html="$t('constructor.extensionModal.htmlInstructionsChrome3')"
              ></div>
            </div>
          </main>
          <aside
            class="modal-extension__actions"
            v-html="$t('constructor.extensionModal.writeUs')"
          ></aside>
        </div>
      </aside>
      <aside class="card-ext__content" v-else>
        <div class="modal-extension">
          <div class="modal-extension__cta">
            <a
              :href="bookmarkletUri"
              :title="$t('bookmarklet.title')"
              class="button button--primary"
              @click.prevent="doNothing"
            >
              {{ $t("bookmarklet.title") }}
            </a>
          </div>

          <main class="modal-extension__items">
            <div class="modal-extension__item">
              <div class="modal-extension__step">
                <div class="modal-extension__cercle">1</div>
              </div>
              <div
                class="modal-extension__desc"
                v-html="$t('constructor.extensionModal.htmlInstructionsGeneric1')"
              ></div>
            </div>

            <div class="modal-extension__item">
              <div class="modal-extension__step">
                <div class="modal-extension__cercle">2</div>
              </div>
              <div
                class="modal-extension__desc"
                v-html="$t('constructor.extensionModal.htmlInstructionsGeneric2')"
              ></div>
            </div>

            <div class="modal-extension__item">
              <div class="modal-extension__step">
                <div class="modal-extension__cercle">3</div>
              </div>
              <div
                class="modal-extension__desc"
                v-html="$t('constructor.extensionModal.htmlInstructionsGeneric3')"
              ></div>
            </div>
          </main>
          <aside
            class="modal-extension__actions"
            v-html="$t('constructor.extensionModal.writeUs')"
          ></aside>
        </div>
      </aside>
    </section>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import Bowser from "bowser";

export default {
  name: "ExtensionExplanationModal",
  data() {
    return {
      browser: null,
      bookmarkletUri: "#",
    };
  },
  computed: {
    isChrome() {
      return this.browser.satisfies({
        chrome: ">=0",
        chromium: ">=0",
      });
    },
  },
  async beforeMount() {
    this.browser = Bowser.getParser(window.navigator.userAgent);

    this.bookmarkletUri = await this.getBookmarkletUri();
  },
  methods: {
    doNothing() {},
    async getBookmarkletUri() {
      const code = await fetch("/api/bookmarklet.js").then((resp) => resp.text());

      return "javascript:" + encodeURIComponent(code);
    },
  },
};
</script>

<style scoped></style>
