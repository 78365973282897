<template>
  <section class="search-next">
    <header class="search-next__header">
      <div class="search-next__title">
        Pega el enlace de un objeto o busca algo
      </div>

      <div class="search-next__actions search-next__actions--d">
        <bbButton
          id="add-fund-button"
          class="button button--outline-dark button--sm"
          @click="addFund"
          label="Añadir fondo"
          v-tooltip="dynamicTooltip"
        >
        </bbButton>
        <router-link to="/preguntas-frecuentes" class="search-next__help">
          <i class="uil uil-question-circle"></i>
          Ayuda
        </router-link>
      </div>
      <div class="search-next__actions search-next__actions--m">
        <span
          class="link link--smaller link--dark link--bold" @click="addFund"
        >
          Añadir fondo
        </span>
        <router-link to="/preguntas-frecuentes" class="search-next__help">
          <i class="uil uil-question-circle"></i>
        </router-link>
      </div>
    </header>
    <main class="search-next__search">
      <mq-layout mq="m992+">
        <input
          type="text"
          class="search-next__input"
          placeholder="Pega un enlace o escribe lo que quieras buscar"
          :value="inputText"
          @input="handleInput"
          @keyup.enter="search"
        />
      </mq-layout>
      <mq-layout :mq="['m480', 'm576', 'm768']">
        <input
          type="text"
          class="search-next__input"
          placeholder="Pega un enlace o busca un objeto"
          :value="inputText"
          @input="handleInput"
          @keyup.enter="search"
        />
      </mq-layout>
      <span
        class="search-next__input-ico"
        :class="isLink ? 'search-next__input-ico--url' : 'search-next__input-ico--search'"
        @click="search"
        v-if="!searchActive"
      >
        <i class="uil" :class="isLink ? 'uil-link' : 'uil-search'"></i>
      </span>
      <span
        class="search-next__input-ico search-next__input-ico--reset"
        @click="resetSearch"
        v-else
      >
        <i class="uil uil-multiply"></i>
      </span>
    </main>
    <div class="search-next__suggestions">
      <div class="search-suggestions" v-if="intellSuggestions.length > 0">
        <span class="search-suggestions__label">Sugerencias</span>
        <span class="search-suggestions__items">
          <span
            class="search-suggestions__item"
            v-for="(suggestion, index) in intellSuggestions"
            :key="index"
            @click="searchWithSuggestion(suggestion)"
            >{{ suggestion }}</span
          >
        </span>
      </div>
    </div>
    <div class="search-next__results" v-if="currentSearchTerm">
      <span>Resultados para:</span>
      <strong>{{ currentSearchTerm }}</strong>
    </div>
    <AddFund ref="refAddButton" />
  </section>
</template>

<script>
import bbButton from "../../../components/buttons/bbButton.vue";
import { mapGetters } from "vuex";
import axios from "axios";
import FundAddButton from "./FundAddButton.vue";
import AddFund from "./AddFund.vue";
import "floating-vue/dist/style.css";
import { VTooltip } from "floating-vue";

import LinkMixin from "./Mixins/Link";

export default {
  name: "SearchConstructorSearch",
  components: {
    FundAddButton,
    bbButton,
    AddFund,
  },
  directives: {
    tooltip: VTooltip,
  },
  mixins: [
    LinkMixin,
  ],
  data() {
    return {
      inputText: "",
      currentSearchTerm: "",
      currentPage: 1,
      suggestions: ["Carritos", "Capazos", "Biberones", "Tronas", "Pañales"],
      dynamicTooltipContent:
        "",
    };
  },
  computed: {
    ...mapGetters({
      searchState: "searchState",
      constructorQuery: "constructorQuery",
      products: "productsConstructor",
    }),
    intellSuggestions() {
      //no suggestions if input length is minor than 3
      if (this.inputText.length < 3) return [];
      //priority to suggestions that starts with the input text
      const startsWith = this.suggestions.filter((s) =>
        s.toLowerCase().startsWith(this.inputText.toLowerCase())
      );
      //priority to suggestions that contains the input text
      const contains = this.suggestions.filter(
        (s) =>
          s.toLowerCase().includes(this.inputText.toLowerCase()) &&
          !startsWith.includes(s)
      );
      return [...startsWith, ...contains];
    },
    searchActive() {
      return this.products.length > 0;
    },
    dynamicTooltip() {
      return {
        html: false,
        content:
          this.dynamicTooltipContent,
        handleResize: false,
        triggers: ["hover", "focus"],
      };
    },
  },
  methods: {
    search() {
      if (this.isLink) {
        window.mixpanel.people.set_once({ "Primera cerca lliure": this.inputText });
        window.mixpanel.track("Cerca lliure", {
          text: this.inputText,
        });
        if (this.$utils.isApp()) {
          var url = this.fixedUrl;
          //clear input text
          this.inputText = ""
          window.location.href = window.location.pathname + "#addfree" + "?u=" + encodeURIComponent(url)
        }
        else {
          this.$router.push({
            name: "add-product",
            query: { url: encodeURI(this.fixedUrl) },
          });
        }
      } else {
        this.searchProducts();
      }
    },
    async searchProducts() {
      if (this.inputText.trim() === "") return;
      const q = this.inputText;
      this.currentSearchTerm = q;
      this.currentPage = 1;

      const parameters = {
        text: q,
        page: this.currentPage,
      };
      this.$store.commit("setConstructorQuery", q);
      await this.$store.dispatch("getProductsConstructor", parameters);

      //track in mixpanel search event
      window.mixpanel.track("Constructor search", {
        "Search term": q,
        "Is Suggestion": "false",
        "Count of results": this.products.length,
      });
    },
    async searchWithSuggestion(suggestion) {
      this.currentSearchTerm = suggestion;
      this.inputText = suggestion;
      this.currentPage = 1;

      const parameters = {
        text: suggestion,
        page: 1,
      };
      this.$store.commit("setConstructorQuery", suggestion);
      await this.$store.dispatch("getProductsConstructor", parameters);

      //track in mixpanel search event
      window.mixpanel.track("Constructor search", {
        "Search term": suggestion,
        "Is Suggestion": "true",
        "Number of results": this.products.length,
      });
    },
    resetSearch() {
      this.inputText = "";
      this.currentSearchTerm = "";
      this.currentPage = 1;
      this.$store.commit("setConstructorQuery", "");
      this.$store.dispatch("resetProductsConstructor");
    },
    verMas() {
      this.currentPage++;
      console.log("verMas", this.currentPage);
      const parameters = {
        text: this.currentSearchTerm,
        page: this.currentPage,
      };
      this.$store.dispatch("getProductsConstructorScroll", parameters);
      this.$emit("isLoading", false);
    },
    handleInput(event) {
      this.inputText = event.target.value;
    },
    addFund() {
      this.$refs.refAddButton.addFund();
    },
  },
  async mounted() {
    this.inputText = this.constructorQuery;
    //feed suggestions through the API https://algolia-service.azurewebsite.net/api/suggestions
    let options = {
      headers: {
        "Content-Type": "application/json",
        "x-functions-key": "CIxr5Tt4Cg6APAVQYWwwQqkecrVIcCEsHvgoqGBB2o8QAzFuh2W4bg==",
      },
    };
    const response = await axios.get(
      "https://algolia-service.azurewebsites.net/api/algolia/suggestions",
      options
    );
    this.suggestions = JSON.parse(response.data);

    this.dynamicTooltipContent =
      "Recolecta dinero para lo que necesites (pañales, guarderías, causas benéficas...)";
  },
};
</script>
