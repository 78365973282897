<template>
  <div class="cs-add" @click.stop="">
    <div class="bb-container bb-container--lista">
      <h2 class="cs-add__title header-3">Añade</h2>

      <div class="cs-add__content bb-card bb-card--max bb-card--shadow-small">
        <div class="cs-add__header">
          <img loading="eager" src="../../../assets/img/ico/ico-carrito.svg" alt="" />
          <h2 class="cs-add__title">Añade cualquier objeto a tu lista</h2>
        </div>
        <form class="cs-add__form">
          <div class="bb-form-inline">
            <div class="bb-form-inline__item">
              <input
                class="bb-form"
                :class="{ 'bb-form--danger': showParseError || urlHasError }"
                v-model="url"
                type="url"
                name="url"
                id="url"
                placeholder="Pega la URL del producto"
                @blur="urlDirty = true"
              />
              <div @click="trackCercaLliure">
                <button
                class="button button--primary button--block"
                @click.prevent="addFreeProduct()"
                :disabled="disabled"
              >
                <img loading="eager" src="../../../assets/img/ico/ico-add.svg" alt="" />
              </button>
            </div>
            </div>
            <div class="bb-form-inline__errors">
              <div class="bb-form-error" v-if="showParseError" tabindex="0">
                Lo sentimos! Ha habido un error procesando la URL.
              </div>
              <div class="bb-form-error" v-if="urlHasError" tabindex="0">
                Por favor, pega aquí el enlace de un producto.
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "AddFreeProduct",
  data() {
    return {
      url: "",
      urlDirty: false,
      showParseError: false,
    };
  },
  computed: {
    ...mapGetters({
      parserError: "parserError",
    }),
    disabled() {
      return this.url === "" || !this.$utils.isValidUrl(this.url);
    },
    urlHasError() {
      return (this.url === ""|| !this.$utils.isValidUrl(this.url)) && this.urlDirty;
    },
  },
  methods: {
    addFreeProduct() {
      this.$router.push({ name: "add-product", query: { url: encodeURI(this.url) } });
    },
    trackCercaLliure() {
      window.mixpanel.people.set_once( { "Primera cerca lliure": this.url });
      window.mixpanel.track("Cerca lliure", {
        text: this.url,
      });
    },
  },
  mounted() {
    if (this.parserError) {
      this.showParseError = true;
      this.$store.commit("setParserError", false);
    }
  },
};
</script>
