<template>
    <portal to="alerts_constructor_page" v-if="showProductAddedConfirmation && show">
      <div class="bb-alert bb-alert--success">
            <div class="bb-alert__ico">
              <i class="uil uil-check"></i>
            </div>
            <div class="bb-alert__left">
              <div class="bb-alert__content bb-alert__content--flex">
                {{ $t("constructor.productAddedToListStrong") }}
              </div>
              <a style="cursor: pointer" class="bb-alert__now" @click.prevent="openOwnerList">Revisa tu lista</a>
              <div class="bb-alert__close" style="cursor: pointer">
                <i class="uil uil-multiply" @click="show=false"></i>
              </div>
            </div>
          </div>
    </portal>
</template>

<script>

export default {
  name: "ProductAddedDialogOk",
  props: {
    showProductAddedConfirmation: {
      type: Boolean,
      default: false,
    }
  },
  data() {
    return {
      show: true,
    };
  },
  methods: {
    openOwnerList() {
      if (!this.$utils.isApp()) {
        this.$router.push({ name: "owner-list" });
      } else {
        location.href = process.env.APP_URL + "mi-lista?source=app";
      }
    },
  }
};

</script>