<template>
  <div class="cs-header">
    <div class="cs-header__content">
      <header class="cs-header__left">
        <div class="cs-header__title">
          <!-- <i
            @click.prevent="$router.push({ name: 'owner-list' })"
            class="uil uil-arrow-left"
          ></i> -->
          ¿No te decides? Mira nuestras sugerencias 👇🏼
        </div>
        <!-- <router-link class="cs-header__help" to="/preguntas-frecuentes">
          <i class="uil uil-question-circle"></i>ww
          Ayuda
        </router-link> -->
      </header>
      <aside class="cs-header__right" v-if="actionsConsctructor">
        <!-- <button class="button button--outline-dark button--sm">
          Añadir de cualquier tienda
        </button> -->
        <AddFreeProductButton></AddFreeProductButton>
        <FundAddButton></FundAddButton>
        <!-- <button class="button button--outline-dark button--sm">Añadir fondo</button> -->
      </aside>
    </div>
  </div>
</template>

<script>
import FundAddButton from "./FundAddButton";
import AddFreeProductButton from "./AddFreeProductButton.vue";
export default {
  name: "HeaderConstructor",
  components: {
    FundAddButton,
    AddFreeProductButton,
  },
  data() {
    return {
      actionsConsctructor: false,
    };
  },
  methods: {
    TrackConstructorClick() {
      mixpanel.track("Click element constructor", {
        Name: "Contáctanos",
        Section: "HeaderConstructor",
        "Section number": "-",
        Type: "Contáctanos",
        Content: "System",
      });
    },
  },
};
</script>
