export default {
    computed: {
        isLink() {
            const urlPattern = new RegExp(
                "^(https?:\\/\\/)?([\\da-z\\.-]+)\\.([a-z\\.]{2,6})([\\/\\w \\.-]*)*\\/?",
                "i"
            );
            return urlPattern.test(this.inputText.trim());
        },
        fixedUrl() {
          if (!this.isLink) {
            return "";
          }

          let url = this.inputText.trim();
          if (url.startsWith("Http")) {
            url = url.replace("Http", "http");
          }
          if (!url.startsWith("https://") && !url.startsWith("http://")) {
            // Assume HTTPS.
            url = `https://${url}`;
          }

          return url;
        },
    },
}
