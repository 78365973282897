import {mapGetters} from "vuex";

export default {
  data() {
    return {
      loading: false,
      showProductAddedDialog: false,
      added: false,
    };
  },
  computed: {
    ...mapGetters({
      list: "ownerList",
    }),
    productRetailersName()
    {
      return this.product.productRetailers.map(function(item) { return item["name"]; });
    },
    SoldByHelloBB()
    {
      if(this.productRetailersName.includes("HelloBB"))
      {
        return "Active";
      }
      return "No";
    },
  },
  methods: {
    async addProduct(source) {
      if(source == "mi-lista") source = "Mi Lista"
      this.loading = true;
      try {
        let body = {}
        if (this.product.shopifyProductId && this.product.shopifyProductVariantId) {
          body = {
            shopifyProductId: this.product.shopifyProductId,
            shopifyProductVariantId: this.product.shopifyProductVariantId
          }
        }
        await this.$store.dispatch("addProductToList",{ list: this.list, product: this.product, body})
      } catch (error) {
        console.log(error);
        this.loading = false;
        return;
      }

      
      this.showProductAddedConfirmation = true;
      this.$sleep(() => this.showProductAddedConfirmation = false, 7000 )
      this.loading = false;
      //if current url contains mi-lista, don't show dialog
      //if (this.$route.name.indexOf("mi-lista") !== -1) this.showProductAddedDialog = true;
      //this.showProductAddedDialog = true;
      //this.$sleep(() => this.showProductAddedDialog = false, 4000 )
      this.$sleep(() => this.added = true, 200 )

      var numRetailers = 1;
      var itemRetailer = 'HelloBB';
      if(this.product.retailerName != 'HelloBB')
      {
        numRetailers = this.product.productRetailers.length;
        itemRetailer = this.product.productRetailers[0].name;
      }

      window.mixpanel.track("Afegir objecte", {
        "Item Category": 'TBD',
        "Item name": this.product.name,
        //"Item Price": this.product.price,
        "Add type": 'Objecte catàleg',
        "Add Platform": this.$getPlatform(),
        "Add client": this.$store.state.cookies.sourceApp == "true" ? "App" : "Webapp",
        Source: source,
        "Num retailers": numRetailers,
        "Item retailer": itemRetailer,
        "Item retailers": this.productRetailersName,
        "Sold by HelloBB": this.SoldByHelloBB,
        "Item URL": "https://www.hellobb.net/p/" + this.product.id,
        "Product ID": this.product.id,
        "Destacat?": this.product.hero ? "Si" : "No"
      });
      window.mixpanel.people.increment("Total Add");
      window.mixpanel.people.increment("List # elements");
      window.mixpanel.people.increment("List value", this.product.price);
      window.mixpanel.people.set({ "Last Add": new Date().toISOString() });
      window.mixpanel.people.set_once({ "First Add": new Date().toISOString() });

      await this.getList()
    },
    async getList(server) {
      return this.$store.dispatch("getOwnerList",server);
    },
    browseProduct() {
      this.$router.push({
        name: "constructor-product",
        params: { brand: this.product["brand-slug"], id: this.product?.id },
      });
    },
    checkIfProductIsAlreadyAddedToList() {
      if (this.list.products) {
        const found = this.list.products.find(
         (product) => product.id === this.product?.id
        );
        if (found) this.added = true;
        return
      }
      this.added = false
    },
    isProductAddedToTheList(id) {
      if (this.list.products) {
        const found = this.list.products.find(
         (product) => product.id === id
        );
        if (found) return true      
      }
      return false
    }
  },
  mounted() {
    this.checkIfProductIsAlreadyAddedToList();
  },
}